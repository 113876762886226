import Phaser from 'phaser';
import { TILE_SIZE } from '../utils/constants';
import { NOTE_1, NOTE_2, NOTE_3, NOTE_4, NOTE_5, NOTE_6, NOTE_7, NOTE_8, NOTE_9, NOTE_10 } from '../utils/constants';
export default class Notes {
    scene;
    player;
    occupiedPositions;
    fovTiles; // FOV tiles array
    note = null;
    soundManager;
    noteKey;
    static notesContent = {
        'note1': { content: NOTE_1, narration: 'note1' },
        'note2': { content: NOTE_2, narration: 'note2' },
        'note3': { content: NOTE_3, narration: 'note3' },
        'note4': { content: NOTE_4, narration: 'note4' },
        'note5': { content: NOTE_5, narration: 'note5' },
        'note6': { content: NOTE_6, narration: 'note6' },
        'note7': { content: NOTE_7, narration: 'note7' },
        'note8': { content: NOTE_8, narration: 'note8' },
        'note9': { content: NOTE_9, narration: 'note9' },
        'note10': { content: NOTE_10, narration: 'note10' },
    };
    static getNotesContent() {
        return Notes.notesContent;
    }
    constructor(scene, player, occupiedPositions, noteKey, fovTiles, soundManager) {
        this.scene = scene;
        this.player = player;
        this.occupiedPositions = occupiedPositions;
        this.fovTiles = fovTiles;
        this.soundManager = soundManager;
        this.noteKey = noteKey;
        // Place the note in the maze
        this.placeNoteInMaze(noteKey);
        // Listen for the 'B' key to open the NoteReadingScene
        this.scene.input.keyboard?.on('keydown-B', this.showNoteReadingScene, this);
        // Update visibility based on FOV
        this.updateVisibility();
        // Listen to scene update event to update visibility
        this.scene.events.on('update', this.updateVisibility, this);
    }
    placeNoteInMaze(noteKey) {
        let pos;
        do {
            pos = this.findRandomOpenTile();
        } while (!pos);
        this.note = this.scene.physics.add.sprite(pos.x * TILE_SIZE, pos.y * TILE_SIZE, 'note')
            .setOrigin(0.5)
            .setInteractive();
        this.note.setData('noteKey', noteKey);
        this.note.setData('noteContent', Notes.notesContent[noteKey].content);
        this.scene.physics.add.overlap(this.player, this.note, () => {
            this.collectNote();
        }, undefined, this);
    }
    collectNote() {
        const noteKey = this.note?.getData('noteKey') || '';
        const noteContent = this.note?.getData('noteContent') || '';
        const collectedNotes = this.scene.registry.get('collectedNotes') || [];
        // Check if the note has already been collected
        if (!collectedNotes.some(note => note.key === noteKey)) {
            collectedNotes.push({ key: noteKey, content: noteContent });
            this.scene.registry.set('collectedNotes', collectedNotes);
            // Destroy the note from the maze
            this.note?.destroy();
            // Play the narration sound for the collected note
            const narrationKey = Notes.notesContent[noteKey]?.narration;
            if (narrationKey) {
                this.soundManager.playSound(narrationKey);
            }
            // Pause the main scene and show the NoteReadingScene
            this.scene.scene.pause('MainScene');
            this.scene.scene.launch('NoteReadingScene', { notes: collectedNotes, noteKey: this.noteKey });
        }
    }
    findRandomOpenTile() {
        let pos;
        do {
            pos = {
                x: Phaser.Math.Between(1, this.scene.physics.world.bounds.width / TILE_SIZE - 2),
                y: Phaser.Math.Between(1, this.scene.physics.world.bounds.height / TILE_SIZE - 2)
            };
        } while (this.occupiedPositions.has(`${pos.x},${pos.y}`) || !this.isTileOpen(pos));
        return pos;
    }
    isTileOpen(pos) {
        const tileX = Math.floor(pos.x);
        const tileY = Math.floor(pos.y);
        if (tileX < 0 ||
            tileX >= this.scene.physics.world.bounds.width / TILE_SIZE ||
            tileY < 0 ||
            tileY >= this.scene.physics.world.bounds.height / TILE_SIZE) {
            return false;
        }
        const tile = this.scene.children.getByName(`tile-${tileX}-${tileY}`);
        return this.scene.physics.world.bounds.contains(tileX * TILE_SIZE, tileY * TILE_SIZE) &&
            !this.occupiedPositions.has(`${tileX},${tileY}`) &&
            tile !== null && tile !== undefined;
    }
    showNoteReadingScene() {
        const collectedNotes = this.scene.registry.get('collectedNotes');
        if (collectedNotes.length > 0) {
            this.scene.scene.pause('MainScene');
            this.scene.scene.launch('NoteReadingScene', { notes: collectedNotes, noteKey: this.noteKey });
        }
    }
    updateVisibility() {
        if (!this.note)
            return;
        const tileX = Math.floor(this.note.x / TILE_SIZE);
        const tileY = Math.floor(this.note.y / TILE_SIZE);
        if (this.fovTiles[tileY] && this.fovTiles[tileY][tileX]) {
            this.note.setAlpha(1);
        }
        else {
            this.note.setAlpha(0);
        }
    }
}
