import Phaser from 'phaser';
export default class GameOverScene extends Phaser.Scene {
    totalScore;
    player;
    soundManager;
    constructor() {
        super({ key: 'GameOverScene' });
    }
    init(data) {
        this.totalScore = data.totalScore;
        this.soundManager = this.registry.get('soundManager');
        if (data.player) {
            this.player = data.player;
        }
    }
    create() {
        const { width, height } = this.scale;
        this.cameras.main.setBackgroundColor('rgba(0, 0, 0, 0.5)');
        this.cameras.main.fadeIn(8000);
        this.add.text(width / 2, height / 2 - 50, 'Game Over', {
            fontSize: '48px',
            color: '#fff'
        }).setOrigin(0.5);
        this.add.text(width / 2, height / 2 - 100, `Total Score: ${this.totalScore}`, {
            fontSize: '32px',
            color: '#fff'
        }).setOrigin(0.5);
        const newGameButton = this.add.text(width / 2, height / 2 + 50, 'New Game', {
            fontSize: '32px',
            color: '#fff',
            backgroundColor: '#000'
        }).setOrigin(0.5).setPadding(10).setInteractive();
        newGameButton.on('pointerdown', () => {
            this.soundManager.stopAllSounds();
            this.resetGameState();
            this.scene.start('MainMenuScene');
        });
        // Play game over music and narration
        this.soundManager.playSound('gameover', { loop: true, volume: 0.75 });
        this.time.delayedCall(2000, () => {
            this.soundManager.playSound('gameovernarration');
        });
        const additionalText = this.add.text(width / 2, height / 2 + 150, "The Minotaur's roar echoes through the labyrinth. Your journey ends here, lost in the maze's dark embrace. The secrets remain hidden, and the beast still reigns supreme.", {
            fontSize: '20px',
            color: '#fff',
            align: 'center',
            wordWrap: { width: width - 40 }
        }).setOrigin(0.5).setAlpha(0);
        this.tweens.add({
            targets: additionalText,
            alpha: 1,
            duration: 8000,
            ease: 'Power2'
        });
    }
    resetGameState() {
        if (this.player) {
            this.player.resetAll();
        }
        this.registry.set('totalScore', 0);
        this.registry.set('currentLevel', 1);
        this.registry.set('score', 0);
        this.registry.set('completedLevels', []);
        this.registry.set('playerData', { gold: 0, inventory: {}, collectedNotes: [] });
        this.registry.set('level', 1);
        this.registry.set('maze', null);
        this.registry.set('collectedNotes', []);
        this.soundManager.stopAllSounds();
        this.time.removeAllEvents();
        this.scene.stop('MainScene');
        this.scene.stop('LevelPickingScene');
        this.scene.stop('JoystickScene');
        this.scene.stop('MinimapScene');
    }
    shutdown() {
        this.soundManager.stopAllSounds();
        this.soundManager.cleanup();
    }
}
