import Phaser from 'phaser';
import soundConfig from './soundConfig'; // Import your sound configuration
export class GlobalSoundManager {
    scene;
    soundLists = {
        background: [],
        item: [],
        narration: [],
    };
    isNarrationPlaying = false;
    activeSounds = new Map();
    constructor(scene) {
        this.scene = scene;
        soundConfig.forEach(({ key, type }) => {
            // Use cache instead of sound manager
            if (this.scene.cache.audio.exists(key)) {
                const sound = this.scene.sound.add(key);
                this.soundLists[type].push(sound);
                //console.log(`Registered sound: ${key}`);
            }
            else {
                console.error(`Sound not preloaded: ${key}`);
            }
        });
    }
    isSoundPlaying(key) {
        const sound = this.activeSounds.get(key);
        return !!sound && sound.isPlaying;
    }
    // Play a sound by key (type is now determined automatically)
    playSound(key, options = {}) {
        // Find sound in all categories
        const sound = Object.values(this.soundLists)
            .flat()
            .find(s => s.key === key);
        if (!sound) {
            console.warn(`Sound not found: ${key}`);
            return;
        }
        // Determine type from configuration
        const type = soundConfig.find(s => s.key === key)?.type || 'item';
        const volume = Phaser.Math.Clamp(options.volume ?? 1.0, 0.0, 1.0);
        if (sound instanceof Phaser.Sound.HTML5AudioSound || sound instanceof Phaser.Sound.WebAudioSound) {
            sound.setVolume(volume);
            sound.setLoop(options.loop ?? false);
        }
        if (type === 'narration') {
            this.handleNarrationSound(key, sound);
        }
        else {
            sound.play();
            console.log(`Playing ${type} sound: ${key} at volume: ${volume}`);
        }
    }
    getSound(key) {
        return Object.values(this.soundLists)
            .flat()
            .find(s => s.key === key);
    }
    // Stop a sound by key (type is determined automatically)
    stopSound(key) {
        const sound = Object.values(this.soundLists)
            .flat()
            .find(s => s.key === key);
        if (sound?.isPlaying) {
            sound.stop();
            console.log(`Stopped sound: ${key}`);
        }
    }
    // Rest of the methods remain unchanged
    stopAllSounds(type) {
        if (type) {
            this.soundLists[type].forEach(sound => {
                sound.stop();
                sound.once('complete', () => sound.destroy()); // Cleanup after stop
            });
        }
        else {
            Object.values(this.soundLists).flat().forEach(sound => {
                sound.stop();
                sound.once('complete', () => sound.destroy());
            });
        }
        this.isNarrationPlaying = false;
        this.activeSounds.clear();
    }
    cleanup() {
        Object.values(this.soundLists).flat().forEach(sound => {
            sound.stop();
            sound.destroy();
        });
        this.soundLists = { background: [], item: [], narration: [] };
        this.isNarrationPlaying = false;
        console.log(`Cleaned up all sounds`);
    }
    handleNarrationSound(key, sound) {
        if (this.isNarrationPlaying) {
            console.log(`Narration already playing. Skipping: ${key}`);
            return;
        }
        console.log(`Playing narration: ${key}`);
        this.isNarrationPlaying = true;
        this.pauseNonNarrationSounds();
        sound.play();
        sound.once('complete', () => {
            console.log(`Narration complete: ${key}`);
            this.isNarrationPlaying = false;
            this.resumeNonNarrationSounds();
        });
    }
    pauseNonNarrationSounds() {
        [...this.soundLists.background, ...this.soundLists.item].forEach(sound => {
            if (sound.isPlaying)
                sound.pause();
        });
    }
    resumeNonNarrationSounds() {
        [...this.soundLists.background, ...this.soundLists.item].forEach(sound => {
            if (sound.isPaused)
                sound.resume();
        });
    }
}
