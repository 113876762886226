import Phaser from 'phaser';
import soundConfig from '../utils/soundConfig';
export default class BootScene extends Phaser.Scene {
    constructor() {
        super('BootScene');
    }
    preload() {
        // Load images
        const tileImages = [
            'player', 'wall', 'floor', 'portal', 'button', 'up', 'down', 'door',
            'dooropen', 'key', 'yarn', 'gold', 'chest', 'minotaur', 'sealed_door',
            'sword', 'shield', 'chestopen', 'magicscroll', 'torch_1', 'torch',
            'map', 'note', 'portalpotion', 'runed_door', 'open_door', 'torch_0',
        ];
        tileImages.forEach(image => this.load.image(image, `assets/tiles/${image}.png`));
        // Load ALL sounds using soundConfig (already includes type metadata)
        soundConfig.forEach(({ key, filePath }) => {
            this.load.audio(key, filePath);
        });
        // Load fonts (if needed)
    }
    create() {
        // Use audio cache instead of sound manager
        //console.log('Loaded sounds:', this.cache.audio.getKeys());
        if (this.sys.game.device.os.android || this.sys.game.device.os.iOS) {
            this.scale.lockOrientation('landscape');
        }
        this.scene.start('MainMenuScene');
    }
}
