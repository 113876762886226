import Phaser from 'phaser';
import { GlobalSoundManager } from '../utils/GlobalSoundManager';
export default class MainMenuScene extends Phaser.Scene {
    soundManager;
    constructor() {
        super('MainMenuScene');
    }
    preload() {
        this.load.image('titlescreen', 'assets/tiles/titlescreen.jpg');
    }
    create() {
        // Initialize sound manager
        this.soundManager = new GlobalSoundManager(this);
        this.registry.set('soundManager', this.soundManager);
        this.soundManager.stopAllSounds();
        const { width, height } = this.scale;
        // Add background image
        this.add.image(width / 2, height / 2, 'titlescreen')
            .setOrigin(0.5)
            .setDisplaySize(width, height);
        // Add title text
        this.add.text(width / 2, height / 4, 'Minotaur Madness', {
            fontSize: '32px',
            color: '#fff'
        }).setOrigin(0.5);
        // Create menu buttons
        this.createButton('Enter Maze', height / 2, () => {
            this.resetGameState();
            this.scene.start('LevelPickingScene');
        });
        this.createButton('Story', height / 2 + 50, () => {
            this.scene.start('StoryScene');
        });
        this.createButton('Instructions', height / 2 + 100, () => {
            this.scene.start('InstructionsScene');
        });
        this.createButton('Options', height / 2 + 150, () => {
            this.scene.start('OptionsScene');
        });
        this.createButton('Credits', height / 2 + 200, () => {
            // Add credits logic
        });
        this.createButton('Close', height / 2 + 250, () => {
            this.closeGame();
        });
        // Play main menu music
        if (!this.soundManager.isSoundPlaying('mainmenu')) {
            this.soundManager.playSound('mainmenu', {
                loop: true,
                volume: 0.75
            });
        }
    }
    createButton(text, yOffset, callback) {
        const button = this.add.text(this.scale.width / 2, yOffset, text, {
            fontSize: '24px',
            color: '#fff',
            backgroundColor: '#000'
        }).setOrigin(0.5).setInteractive();
        button.on('pointerover', () => {
            this.soundManager.playSound('hover');
            button.setStyle({ backgroundColor: '#333' });
        });
        button.on('pointerout', () => {
            button.setStyle({ backgroundColor: '#000' });
        });
        button.on('pointerdown', () => {
            this.soundManager.playSound('click');
            callback();
        });
    }
    closeGame() {
        if (window.confirm("Are you sure you want to close the game?")) {
            window.close();
        }
    }
    resetGameState() {
        // Full game state reset
        this.registry.set('totalScore', 0);
        this.registry.set('currentLevel', 1);
        this.registry.set('completedLevels', []);
        this.registry.set('playerData', {
            gold: 0,
            inventory: {},
            collectedNotes: []
        });
        this.registry.set('level', 1);
        this.registry.set('maze', null);
        // Cleanup previous scenes
        this.scene.stop('LevelPickingScene');
        this.scene.stop('MainScene');
    }
    shutdown() {
        // Stop specific background music and clean up
        this.soundManager.stopAllSounds();
        this.soundManager.cleanup();
    }
}
