import Phaser from 'phaser';
export default class StoryScene extends Phaser.Scene {
    storyLines;
    currentLineIndex;
    storyText;
    continueButton;
    skipButton;
    soundManager;
    totalStoryDuration = 80000;
    constructor() {
        super({ key: 'StoryScene' });
        this.storyLines = [
            "In ancient Crete, there was a powerful king named Minos. He harbored a terrible secret.",
            "His wife had given birth to a fearsome creature, half-man, half-bull—the Minotaur.",
            "To imprison this beast, Minos commanded the brilliant inventor Daedalus to create a labyrinth, an inescapable maze so intricate that no one who entered could ever find their way out.",
            "",
            "The Minotaur roamed these dark, winding passages, its only purpose to guard the maze and eliminate any who dared enter.",
            "For years, many brave souls tried to conquer the labyrinth, but all fell prey to its deadly traps and the monstrous guardian within.",
            "",
            "Now, you stand at the entrance to this ancient maze. The echoes of lost adventurers linger, and the distant roar of the Minotaur chills the air.",
            "Your quest is to navigate the treacherous paths, outsmart the traps, and avoid the relentless Minotaur.",
            "Can you uncover the hidden secrets, gather the tools you need, and ultimately escape the labyrinth’s clutches?",
        ];
        this.currentLineIndex = 0;
    }
    init() {
        this.soundManager = this.registry.get('soundManager');
    }
    create() {
        const { width, height } = this.scale;
        // Setup audio
        this.soundManager.playSound('gameover', { loop: true, volume: 0.5 });
        this.soundManager.playSound('story');
        // Visual setup
        this.add.rectangle(0, 0, width, height, 0x000000).setOrigin(0);
        this.storyText = this.add.text(width / 2, height / 2, '', {
            fontSize: '24px',
            color: '#fff',
            align: 'left',
            wordWrap: { width: width - 100 },
        }).setOrigin(0.5, 0.5);
        this.add.text(width / 2, 50, 'The Labyrinth of the Minotaur', {
            fontSize: '36px',
            color: '#fff',
            align: 'center'
        }).setOrigin(0.5);
        // Interactive elements
        this.createInteractiveButtons(width, height);
        // Start story sequence
        this.time.delayedCall(1000, this.showNextLine, [], this);
    }
    createInteractiveButtons(width, height) {
        // Continue Button
        this.continueButton = this.add.text(width / 2, height - 100, 'Continue', {
            fontSize: '24px',
            color: '#fff',
            backgroundColor: '#000',
            padding: { x: 10, y: 5 }
        })
            .setOrigin(0.5)
            .setInteractive()
            .setAlpha(0);
        // Skip Button
        this.skipButton = this.add.text(width / 2, height - 50, 'Skip', {
            fontSize: '20px',
            color: '#fff',
            backgroundColor: '#000',
            padding: { x: 10, y: 5 }
        })
            .setOrigin(0.5)
            .setInteractive();
        // Button interactions
        [this.continueButton, this.skipButton].forEach(button => {
            button.on('pointerover', () => {
                button.setStyle({ backgroundColor: '#333' });
                this.soundManager.playSound('hover');
            });
            button.on('pointerout', () => button.setStyle({ backgroundColor: '#000' }));
            button.on('pointerdown', () => {
                this.soundManager.playSound('click');
                this.cleanupAndTransition();
            });
        });
    }
    showNextLine() {
        if (this.currentLineIndex >= this.storyLines.length) {
            this.time.delayedCall(1000, () => this.continueButton.setAlpha(1), [], this);
            return;
        }
        const line = this.storyLines[this.currentLineIndex];
        this.animateText(line);
        const wordCount = line.split(' ').length;
        const lineDuration = (wordCount / this.getTotalWordCount()) * this.totalStoryDuration;
        this.time.delayedCall(lineDuration - 3000, () => {
            this.tweens.add({
                targets: this.storyText,
                alpha: 0,
                duration: 1500,
                onComplete: () => {
                    this.currentLineIndex++;
                    this.showNextLine();
                }
            });
        });
    }
    animateText(line) {
        this.storyText.setText(line).setAlpha(0);
        this.tweens.add({
            targets: this.storyText,
            alpha: 1,
            duration: 1500
        });
    }
    getTotalWordCount() {
        return this.storyLines.reduce((count, line) => count + line.split(' ').length, 0);
    }
    cleanupAndTransition() {
        this.soundManager.stopSound('gameover');
        this.soundManager.stopSound('story');
        this.scene.start('LevelPickingScene');
    }
    shutdown() {
        this.soundManager.stopSound('gameover');
        this.soundManager.stopSound('story');
    }
}
