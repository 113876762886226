import Phaser from 'phaser';
import { TILE_SIZE } from '../utils/constants';
export default class MinimapItem extends Phaser.Physics.Arcade.Sprite {
    fovTiles;
    constructor(scene, x, y, fovTiles) {
        super(scene, x, y, 'minimap');
        scene.add.existing(this);
        scene.physics.add.existing(this);
        this.fovTiles = fovTiles;
        this.setOrigin(0.5);
        this.setScale(0.5);
        if (this.body) {
            this.body.setSize(TILE_SIZE, TILE_SIZE);
            this.body.setOffset(16, 16);
        }
        scene.events.on('update', this.updateVisibility, this);
    }
    static addMinimapItem(scene, player, occupiedPositions, soundManager, fovTiles) {
        let mapX, mapY;
        const maze = scene.registry.get('maze');
        do {
            mapX = Phaser.Math.Between(1, maze[0].length - 2);
            mapY = Phaser.Math.Between(1, maze.length - 2);
        } while (maze[mapY][mapX] !== 0 || occupiedPositions.has(`${mapX},${mapY}`));
        const mapItem = new MinimapItem(scene, mapX * TILE_SIZE + TILE_SIZE / 2, mapY * TILE_SIZE + TILE_SIZE / 2, fovTiles);
        scene.physics.add.overlap(player, mapItem, () => {
            if (player) {
                player.addItem('map');
            }
            if (mapItem) {
                mapItem.destroy();
            }
            if (soundManager) {
                soundManager.playSound('yarnpickup');
                soundManager.playSound(Phaser.Math.RND.pick(['map1', 'map2', 'map3']));
            }
        });
        occupiedPositions.add(`${mapX},${mapY}`);
    }
    updateVisibility() {
        const tileX = Math.floor(this.x / TILE_SIZE);
        const tileY = Math.floor(this.y / TILE_SIZE);
        this.setAlpha(this.fovTiles[tileY]?.[tileX] ? 1 : 0);
    }
}
