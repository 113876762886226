import Phaser from 'phaser';
export default class GameCompleteScene extends Phaser.Scene {
    totalScore;
    soundManager;
    constructor() {
        super({ key: 'GameCompleteScene' });
    }
    init(data) {
        this.totalScore = data.totalScore;
        this.soundManager = this.registry.get('soundManager');
    }
    create() {
        const { width, height } = this.scale;
        this.add.text(width / 2, height / 2 - 100, 'Congratulations, Hero!', {
            fontSize: '48px',
            color: '#fff'
        }).setOrigin(0.5);
        this.add.text(width / 2, height / 2 - 50, `Total Score: ${this.totalScore}`, {
            fontSize: '32px',
            color: '#fff'
        }).setOrigin(0.5);
        this.add.text(width / 2, height / 2, `You have conquered the Labyrinth,\n and defeated the Minotaur,\n the beast that lurks within the shadows.\nYour courage and wit have led you to victory.\nBut beware, the legend says...\n the Labyrinth may change,\n and the Minotaur will rise again.`, {
            fontSize: '24px',
            color: '#fff',
            align: 'center',
            wordWrap: { width: width - 60 }
        }).setOrigin(0.5);
        const restartButton = this.add.text(width / 2, height / 2 + 150, 'Restart Game', {
            fontSize: '32px',
            color: '#fff',
            backgroundColor: '#000'
        }).setOrigin(0.5).setPadding(10).setInteractive();
        restartButton.on('pointerdown', () => {
            this.resetGameState();
            this.scene.start('MainScene', { level: 1, totalScore: 0 });
        });
        const endButton = this.add.text(width / 2, height / 2 + 220, 'End Game', {
            fontSize: '32px',
            color: '#fff',
            backgroundColor: '#000'
        }).setOrigin(0.5).setPadding(10).setInteractive();
        endButton.on('pointerdown', () => {
            this.game.destroy(true, false);
        });
        this.time.delayedCall(2000, () => {
            this.soundManager.playSound('gamecompletenarration');
        });
    }
    resetGameState() {
        this.registry.set('totalScore', 0);
        this.registry.set('currentLevel', 1);
        this.registry.set('score', 0);
        this.registry.set('completedLevels', []);
        this.registry.set('playerData', { gold: 0, inventory: {} });
        this.registry.set('level', 1);
        this.registry.set('maze', null);
        this.registry.set('collectedNotes', []);
    }
    shutdown() {
        this.soundManager.stopAllSounds();
        this.soundManager.cleanup();
    }
}
