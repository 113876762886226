import Phaser from 'phaser';
import EasyStar from 'easystarjs';
import { TILE_SIZE } from '../utils/constants';
import { shuffleArray } from '../utils/helpers';
export default class Yarn {
    scene;
    player;
    occupiedPositions;
    yarn;
    soundManager;
    pathfinder;
    doorPosition;
    graphics;
    pathTiles = [];
    constructor(scene, player, occupiedPositions, soundManager) {
        this.scene = scene;
        this.player = player;
        this.occupiedPositions = occupiedPositions;
        this.soundManager = soundManager;
        this.pathfinder = new EasyStar.js();
        this.pathfinder.setGrid(this.getMazeGrid());
        this.pathfinder.setAcceptableTiles([0]);
        this.graphics = this.scene.add.graphics();
    }
    addYarn(deadEnds) {
        if (deadEnds.length < 1)
            return;
        const [yarnPos] = shuffleArray(deadEnds).slice(0, 1);
        this.yarn = this.scene.physics.add.sprite(yarnPos.x * TILE_SIZE, yarnPos.y * TILE_SIZE, 'yarn').setOrigin(0, 0);
        this.yarn.setDepth(0);
        this.yarn.setAlpha(0);
        this.scene.physics.add.overlap(this.player, this.yarn, this.collectYarn, undefined, this);
        this.occupiedPositions.add(`${yarnPos.x},${yarnPos.y}`);
    }
    collectYarn = () => {
        if (this.yarn) {
            this.player.addItem('yarn');
            this.scene.events.emit('updateInventory');
            this.yarn.destroy();
            this.soundManager.playSound('yarnpickup');
            this.soundManager.playSound(Phaser.Math.RND.pick(['yarn3', 'yarn4']));
        }
    };
    updateVisibility(fovTiles) {
        if (this.yarn) {
            const tileX = Math.floor(this.yarn.x / TILE_SIZE);
            const tileY = Math.floor(this.yarn.y / TILE_SIZE);
            this.yarn.setAlpha(fovTiles[tileY]?.[tileX] ? 1 : 0);
        }
    }
    updatePathVisibility(fovTiles) {
        this.graphics.clear();
        this.graphics.lineStyle(1, 0xffff00, 1);
        let pathVisible = false;
        for (const tile of this.pathTiles) {
            if (fovTiles[tile.y]?.[tile.x] || this.player.hasMagicScroll) {
                if (!pathVisible) {
                    this.graphics.beginPath();
                    this.graphics.moveTo(tile.x * TILE_SIZE + TILE_SIZE / 2, tile.y * TILE_SIZE + TILE_SIZE / 2);
                    pathVisible = true;
                }
                else {
                    this.graphics.lineTo(tile.x * TILE_SIZE + TILE_SIZE / 2, tile.y * TILE_SIZE + TILE_SIZE / 2);
                }
            }
        }
        if (pathVisible) {
            this.graphics.strokePath();
            this.graphics.closePath();
        }
    }
    setDoorPosition(doorPosition) {
        this.doorPosition = doorPosition;
    }
    createPathToAdjacentTile() {
        const startX = Math.floor(this.player.x / TILE_SIZE);
        const startY = Math.floor(this.player.y / TILE_SIZE);
        const adjacentTiles = this.getAdjacentTiles(this.doorPosition);
        let pathFound = false;
        for (const tile of adjacentTiles) {
            const endX = tile.x;
            const endY = tile.y;
            const grid = this.getMazeGrid();
            grid[startY][startX] = 0;
            grid[endY][endX] = 0;
            this.pathfinder.setGrid(grid);
            this.pathfinder.findPath(startX, startY, endX, endY, (path) => {
                if (path !== null) {
                    this.drawPath(path);
                    this.player.removeItem('yarn');
                    this.yarn = undefined;
                    this.soundManager.playSound(Phaser.Math.RND.pick(['scroll1', 'scroll2']));
                    pathFound = true;
                }
            });
            this.pathfinder.calculate();
            if (pathFound)
                break;
        }
    }
    getAdjacentTiles(position) {
        const { x, y } = position;
        const adjacentTiles = [
            { x: x - 1, y },
            { x: x + 1, y },
            { x, y: y - 1 },
            { x, y: y + 1 },
        ];
        return adjacentTiles.filter(tile => {
            const maze = this.scene.registry.get('maze');
            return maze[tile.y]?.[tile.x] === 0;
        });
    }
    drawPath(path) {
        this.graphics.clear();
        this.graphics.lineStyle(2, 0xffff00, 1);
        const points = path.map(p => new Phaser.Math.Vector2(p.x * TILE_SIZE + TILE_SIZE / 2, p.y * TILE_SIZE + TILE_SIZE / 2));
        new Phaser.Curves.Spline(points).draw(this.graphics);
        this.graphics.strokePath();
    }
    getMazeGrid() {
        const maze = this.scene.registry.get('maze');
        return maze.map(row => row.map(cell => cell === 1 ? 1 : 0));
    }
}
