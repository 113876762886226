import Phaser from 'phaser';
import { TILE_SIZE, TEMP_PORTAL_COOLDOWN } from '../utils/constants';
import { shuffleArray, findDeadEnds } from '../utils/helpers';
export default class Portals {
    scene;
    player;
    occupiedPositions;
    portals;
    portal1;
    portal2;
    cooldown = 3000;
    lastTeleportTime = 0;
    soundManager;
    lastPortalProximityTime = 0;
    permanentlyVisiblePortals;
    temporaryPortalCooldown = TEMP_PORTAL_COOLDOWN;
    constructor(scene, player, occupiedPositions, soundManager) {
        this.scene = scene;
        this.player = player;
        this.occupiedPositions = occupiedPositions;
        this.portals = this.scene.physics.add.group();
        this.soundManager = soundManager;
        this.permanentlyVisiblePortals = new Set();
        player.setPortals(this);
    }
    spawnTemporaryPortal() {
        const deadEnds = this.getDeadEndsInDifferentQuadrant();
        if (deadEnds.length === 0)
            return;
        const randomDeadEnd = Phaser.Math.RND.pick(deadEnds);
        const playerPortal = this.scene.physics.add.sprite(this.player.x, this.player.y, 'portal');
        const targetPortal = this.scene.physics.add.sprite(randomDeadEnd.x * TILE_SIZE, randomDeadEnd.y * TILE_SIZE, 'portal');
        playerPortal.setOrigin(0, 0);
        targetPortal.setOrigin(0, 0);
        this.portals.add(playerPortal);
        this.portals.add(targetPortal);
        this.scene.physics.add.overlap(this.player, playerPortal, () => {
            this.teleportPlayer(targetPortal);
        }, undefined, this);
        this.scene.physics.add.overlap(this.player, targetPortal, () => {
            this.teleportPlayer(playerPortal);
        }, undefined, this);
        this.scene.time.delayedCall(this.temporaryPortalCooldown, () => {
            playerPortal.destroy();
            targetPortal.destroy();
        });
        this.player.disableInput();
        this.scene.time.delayedCall(500, () => {
            this.player.enableInput();
        }, [], this);
    }
    getDeadEndsInDifferentQuadrant() {
        const deadEnds = this.getDeadEnds();
        const playerQuadrant = this.getQuadrant(this.player.x / TILE_SIZE, this.player.y / TILE_SIZE);
        return deadEnds.filter(deadEnd => this.getQuadrant(deadEnd.x, deadEnd.y) !== playerQuadrant);
    }
    getQuadrant(x, y) {
        const mazeWidth = this.scene.registry.get('maze')[0].length;
        const mazeHeight = this.scene.registry.get('maze').length;
        const halfWidth = mazeWidth / 2;
        const halfHeight = mazeHeight / 2;
        if (x < halfWidth && y < halfHeight)
            return 'top-left';
        if (x >= halfWidth && y < halfHeight)
            return 'top-right';
        if (x < halfWidth && y >= halfHeight)
            return 'bottom-left';
        return 'bottom-right';
    }
    getDeadEnds() {
        const maze = this.scene.registry.get('maze');
        return findDeadEnds(maze);
    }
    addPortals(deadEnds) {
        if (deadEnds.length < 2)
            return;
        const [portal1Pos, portal2Pos] = shuffleArray(deadEnds).slice(0, 2);
        this.portal1 = this.scene.physics.add.sprite(portal1Pos.x * TILE_SIZE, portal1Pos.y * TILE_SIZE, 'portal');
        this.portal2 = this.scene.physics.add.sprite(portal2Pos.x * TILE_SIZE, portal2Pos.y * TILE_SIZE, 'portal');
        this.portal1.setOrigin(0, 0).setAlpha(0);
        this.portal2.setOrigin(0, 0).setAlpha(0);
        this.portals.add(this.portal1);
        this.portals.add(this.portal2);
        this.scene.physics.add.overlap(this.player, this.portal1, () => {
            this.teleportPlayer(this.portal2);
        }, undefined, this);
        this.scene.physics.add.overlap(this.player, this.portal2, () => {
            this.teleportPlayer(this.portal1);
        }, undefined, this);
        this.occupiedPositions.add(`${portal1Pos.x},${portal1Pos.y}`);
        this.occupiedPositions.add(`${portal2Pos.x},${portal2Pos.y}`);
    }
    getPortals() {
        return this.portals.getChildren();
    }
    teleportPlayer(targetPortal) {
        const currentTime = this.scene.time.now;
        if (currentTime - this.lastTeleportTime > this.cooldown) {
            this.player.setPosition(targetPortal.x, targetPortal.y);
            this.lastTeleportTime = currentTime;
            this.soundManager.playSound('portaltransfer');
            this.player.disableInput();
            this.scene.time.delayedCall(500, () => {
                this.player.enableInput();
            }, [], this);
        }
    }
    update() {
        const currentTime = this.scene.time.now;
        this.portals.getChildren().forEach((portal) => {
            const portalSprite = portal;
            const portalDistance = Phaser.Math.Distance.Between(this.player.x, this.player.y, portalSprite.x, portalSprite.y);
            if (portalDistance < TILE_SIZE * 3 &&
                portalDistance > TILE_SIZE &&
                currentTime - this.lastPortalProximityTime > 10000) {
                this.soundManager.playSound('portalisnear', { volume: 0.05 });
                this.lastPortalProximityTime = currentTime;
            }
        });
    }
    updateVisibility(fovTiles) {
        const updateObjectVisibility = (object) => {
            if (object) {
                const tileX = Math.floor(object.x / TILE_SIZE);
                const tileY = Math.floor(object.y / TILE_SIZE);
                const portalKey = `${tileX},${tileY}`;
                if (this.player.hasMagicScroll && fovTiles[tileY]?.[tileX]) {
                    this.permanentlyVisiblePortals.add(portalKey);
                    object.setAlpha(1);
                }
                else if (fovTiles[tileY]?.[tileX] || this.permanentlyVisiblePortals.has(portalKey)) {
                    object.setAlpha(1);
                }
                else {
                    object.setAlpha(0);
                }
            }
        };
        this.getPortals().forEach(portal => {
            updateObjectVisibility(portal);
        });
    }
}
