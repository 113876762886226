import Phaser from 'phaser';
import Notes from '../entities/Notes';
export default class NoteReadingScene extends Phaser.Scene {
    notes = [];
    currentNoteIndex = 0;
    noteTextObject;
    notepadTextObject;
    notepadInput = '';
    soundManager;
    noteKey;
    mainPanel;
    defaultNote = `
    Key Bindings:
- W, A, S, D or Arrow Keys: Move your character
- B: Close notebook
- N: Next note
- V: Previous note
- I: Inventory
- M: Minimap
- Y: Yarn trail
- T: Torch
- P: Portal potion

Objective:
Find the exit key, avoid dangers,
collect items, and solve maze puzzles.
`;
    constructor() {
        super('NoteReadingScene');
    }
    init(data) {
        this.soundManager = this.registry.get('soundManager');
        this.notes = data.notes.map(note => note.content);
        this.notes.unshift(this.defaultNote);
        this.noteKey = data.noteKey;
    }
    create() {
        this.createBackground();
        this.createMainPanel();
        this.createNoteText();
        this.createControls();
        this.setupKeyboard();
        this.playNarration();
    }
    createBackground() {
        this.add.rectangle(0, 0, this.scale.width, this.scale.height, 0x000000, 0.7)
            .setOrigin(0);
    }
    createMainPanel() {
        this.mainPanel = this.add.rectangle(this.scale.width / 2, this.scale.height / 2, this.scale.width * 0.8, this.scale.height * 0.8, 0x222222).setStrokeStyle(2, 0xffffff);
    }
    createNoteText() {
        this.noteTextObject = this.add.text(this.mainPanel.x - this.mainPanel.width / 2 + 40, this.mainPanel.y - this.mainPanel.height / 2 + 40, this.notes[this.currentNoteIndex], {
            fontFamily: 'Arial',
            fontSize: '20px',
            color: '#ffffff',
            wordWrap: { width: this.mainPanel.width - 80 }
        }).setLineSpacing(10);
    }
    createControls() {
        const buttonStyle = {
            fontFamily: 'Arial',
            fontSize: '18px',
            color: '#ffffff',
            backgroundColor: '#444444',
            padding: { x: 10, y: 5 }
        };
        // Navigation Buttons
        this.add.text(this.mainPanel.x - 100, this.mainPanel.y + this.mainPanel.height / 2 - 40, '[N] Next Note', buttonStyle).setInteractive().on('pointerdown', this.showNextNote.bind(this));
        this.add.text(this.mainPanel.x + 20, this.mainPanel.y + this.mainPanel.height / 2 - 40, '[V] Previous Note', buttonStyle).setInteractive().on('pointerdown', this.showPreviousNote.bind(this));
        // Media Controls
        this.add.text(this.mainPanel.x - 100, this.mainPanel.y - this.mainPanel.height / 2 + 40, '[P] Play', buttonStyle).setInteractive().on('pointerdown', this.playNarration.bind(this));
        this.add.text(this.mainPanel.x + 20, this.mainPanel.y - this.mainPanel.height / 2 + 40, '[S] Stop', buttonStyle).setInteractive().on('pointerdown', this.stopNarration.bind(this));
        // Close Button
        this.add.text(this.mainPanel.x + this.mainPanel.width / 2 - 100, this.mainPanel.y - this.mainPanel.height / 2 + 40, '[B] Close', { ...buttonStyle, backgroundColor: '#aa0000' }).setInteractive().on('pointerdown', this.closeScene.bind(this));
    }
    setupKeyboard() {
        this.input.keyboard?.on('keydown-B', this.closeScene, this);
        this.input.keyboard?.on('keydown-N', this.showNextNote, this);
        this.input.keyboard?.on('keydown-V', this.showPreviousNote, this);
        this.input.keyboard?.on('keydown-P', this.playNarration, this);
        this.input.keyboard?.on('keydown-S', this.stopNarration, this);
    }
    playNarration() {
        const noteData = Notes.getNotesContent()[this.noteKey];
        if (noteData) {
            this.soundManager.playSound(noteData.narration);
        }
    }
    stopNarration() {
        const noteData = Notes.getNotesContent()[this.noteKey];
        if (noteData) {
            this.soundManager.stopSound(noteData.narration);
        }
    }
    showNextNote() {
        this.currentNoteIndex = (this.currentNoteIndex + 1) % this.notes.length;
        this.updateNoteDisplay();
        this.soundManager.playSound('pageturn');
    }
    showPreviousNote() {
        this.currentNoteIndex = (this.currentNoteIndex - 1 + this.notes.length) % this.notes.length;
        this.updateNoteDisplay();
        this.soundManager.playSound('pageturn2');
    }
    updateNoteDisplay() {
        this.noteTextObject.setText(this.notes[this.currentNoteIndex]);
        this.tweens.add({
            targets: this.noteTextObject,
            alpha: 0,
            duration: 200,
            yoyo: true,
            onComplete: () => this.noteTextObject.setAlpha(1)
        });
    }
    shutdown() {
        // Stop all sounds associated with this scene
        this.soundManager.stopAllSounds();
        // Specifically stop narration if needed
        const noteData = Notes.getNotesContent()[this.noteKey];
        if (noteData) {
            this.soundManager.stopSound(noteData.narration);
        }
        // Clear any active sound references
        this.soundManager.cleanup();
        // Remove keyboard listeners
        this.input.keyboard?.off('keydown-B');
        this.input.keyboard?.off('keydown-N');
        this.input.keyboard?.off('keydown-V');
        this.input.keyboard?.off('keydown-P');
        this.input.keyboard?.off('keydown-S');
    }
    closeScene() {
        // Stop sounds before scene transition
        this.soundManager.stopAllSounds();
        this.soundManager.playSound('click');
        // Clean up scene properly
        if (this.scene.isPaused('MainScene')) {
            this.scene.resume('MainScene');
        }
        this.scene.stop();
    }
}
