const soundConfig = [
    // ===== Background Sounds =====
    { key: 'running', filePath: 'assets/sounds/running.mp3', type: 'background' },
    { key: 'chaseloop', filePath: 'assets/sounds/chaseloop.mp3', type: 'background' },
    { key: 'soundtrack', filePath: 'assets/sounds/soundtrack.mp3', type: 'background' },
    { key: 'mainmenu', filePath: 'assets/sounds/mainmenu.mp3', type: 'background' },
    { key: 'gameover', filePath: 'assets/sounds/gameover.mp3', type: 'background' },
    // ===== Item Sounds =====
    { key: 'growl', filePath: 'assets/sounds/growl.mp3', type: 'item' },
    { key: 'minotaurcontact', filePath: 'assets/sounds/minotaurcontact.mp3', type: 'item' },
    { key: 'minotaurisnear', filePath: 'assets/sounds/minotaurisnear.mp3', type: 'item' },
    { key: 'minotaurisnear2', filePath: 'assets/sounds/minotaurisnear2.mp3', type: 'item' },
    { key: 'portaltransfer', filePath: 'assets/sounds/portaltransfer.mp3', type: 'item' },
    { key: 'portalisnear', filePath: 'assets/sounds/portalisnear.mp3', type: 'item' },
    { key: 'monsterLOS', filePath: 'assets/sounds/monsterLOS.mp3', type: 'item' },
    { key: 'pageturn', filePath: 'assets/sounds/pageturn.mp3', type: 'item' },
    { key: 'pageturn2', filePath: 'assets/sounds/pageturn2.mp3', type: 'item' },
    { key: 'shield', filePath: 'assets/sounds/shield.mp3', type: 'item' },
    { key: 'sword', filePath: 'assets/sounds/sword.mp3', type: 'item' },
    { key: 'torchuse', filePath: 'assets/sounds/torchuse.mp3', type: 'item' },
    { key: 'torchon', filePath: 'assets/sounds/torchon.mp3', type: 'item' },
    { key: 'key', filePath: 'assets/sounds/key.mp3', type: 'item' },
    { key: 'gate', filePath: 'assets/sounds/gate.mp3', type: 'item' },
    { key: 'chest', filePath: 'assets/sounds/chest.mp3', type: 'item' },
    { key: 'coin', filePath: 'assets/sounds/coin.mp3', type: 'item' },
    { key: 'yarnpickup', filePath: 'assets/sounds/yarnpickup.mp3', type: 'item' },
    { key: 'click', filePath: 'assets/sounds/click.mp3', type: 'item' },
    { key: 'hover', filePath: 'assets/sounds/hover.mp3', type: 'item' },
    { key: 'inventoryopen', filePath: 'assets/sounds/inventoryopen.mp3', type: 'item' },
    { key: 'inventoryclose', filePath: 'assets/sounds/inventoryclose.mp3', type: 'item' },
    { key: 'error', filePath: 'assets/sounds/error.mp3', type: 'item' },
    // ===== Narration Sounds =====
    { key: 'gameovernarration', filePath: 'assets/sounds/gameovernarration.mp3', type: 'narration' },
    { key: 'gamecompletenarration', filePath: 'assets/sounds/gamecompletenarration.mp3', type: 'narration' },
    { key: 'levelcompletenarration', filePath: 'assets/sounds/levelcompletenarration.mp3', type: 'narration' },
    { key: 'story', filePath: 'assets/sounds/story.mp3', type: 'narration' },
    // ===== Dynamic Sound Arrays =====
    // Level Narration
    ...['levels1', 'levels2', 'levels3', 'levels4', 'levels5', 'levels6'].map(key => ({
        key,
        filePath: `assets/sounds/${key}.mp3`,
        type: 'narration'
    })),
    // Main Narration
    ...['main1', 'main2', 'main3', 'main4', 'main5', 'main6', 'main7', 'main8'].map(key => ({
        key,
        filePath: `assets/sounds/${key}.mp3`,
        type: 'narration'
    })),
    // Item Pickups
    ...['yarn3', 'yarn4', 'scroll1', 'scroll2', 'scroll3', 'map1', 'map2', 'map3'].map(key => ({
        key,
        filePath: `assets/sounds/${key}.mp3`,
        type: 'item'
    })),
    // Environment Interactions
    ...['torch1', 'torch2', 'gold1', 'gold2', 'gold3', 'store1', 'store2', 'store3', 'store4'].map(key => ({
        key,
        filePath: `assets/sounds/${key}.mp3`,
        type: 'item'
    })),
    // Key/Potion Sounds
    ...['key1', 'key2', 'key3', 'potion1', 'potion2', 'potion3', 'potion4'].map(key => ({
        key,
        filePath: `assets/sounds/${key}.mp3`,
        type: 'item'
    })),
    // Ambient Background
    ...[
        'idle1', 'idle2', 'idle3', 'idle4', 'idle5',
        'idle6', 'idle7', 'idle8', 'idle9', 'idle10'
    ].map(key => ({
        key,
        filePath: `assets/sounds/${key}.mp3`,
        type: 'background'
    })),
    // Story Notes
    ...[
        'note1', 'note2', 'note3', 'note4', 'note5',
        'note6', 'note7', 'note8', 'note9', 'note10'
    ].map(key => ({
        key,
        filePath: `assets/sounds/${key}.mp3`,
        type: 'narration'
    })),
];
export default soundConfig;
