import Phaser from 'phaser';
const TEXT_STYLE = {
    title: { fontSize: '32px', color: '#fff' },
    gold: { fontSize: '16px', color: '#fff' },
    item: { fontSize: '16px', color: '#fff', align: 'center' }
};
const ITEM_SPACING = 100;
const BASE_Y_POSITION = 0.5;
export default class StoreScene extends Phaser.Scene {
    player;
    storeText;
    goldText;
    soundManager;
    storeItems = new Map();
    storeConfig = [
        { texture: 'sword', price: 200, stock: 2, position: { x: -200, y: 0 } },
        { texture: 'shield', price: 50, stock: 2, position: { x: -100, y: 0 } },
        { texture: 'minimap', price: 100, stock: 1, position: { x: 0, y: 0 } },
        { texture: 'magicscroll', price: 100, stock: 1, position: { x: 100, y: 0 } },
        { texture: 'torch', price: 20, stock: 3, position: { x: 200, y: 0 } },
        { texture: 'portalpotion', price: 150, stock: 1, position: { x: 300, y: 0 } }
    ];
    constructor() {
        super('StoreScene');
    }
    init(data) {
        this.player = data.player;
        this.soundManager = data.soundManager;
        this.player.disableInput();
    }
    create() {
        this.createBackground();
        this.createUIElements();
        this.createStoreItems();
        this.setupInput();
    }
    createBackground() {
        const { width, height } = this.scale;
        this.add.rectangle(0, 0, width, height, 0x000000, 0.5)
            .setOrigin(0)
            .setInteractive()
            .on('pointerdown', this.toggleStore);
    }
    createUIElements() {
        const { width, height } = this.scale;
        this.storeText = this.add.text(width / 2, height / 4, 'Store', TEXT_STYLE.title)
            .setOrigin(0.5);
        this.goldText = this.add.text(20, 20, `Gold: ${this.player.getGold()}`, TEXT_STYLE.gold)
            .setScrollFactor(0);
    }
    createStoreItems() {
        const centerX = this.scale.width / 2;
        const centerY = this.scale.height * BASE_Y_POSITION;
        this.storeConfig.forEach(config => {
            // Add type assertion here
            const itemKey = config.texture;
            const x = centerX + config.position.x;
            const y = centerY + config.position.y;
            const image = this.add.image(x, y, itemKey)
                .setInteractive({ useHandCursor: true })
                .on('pointerover', () => this.handleItemHover(itemKey))
                .on('pointerout', () => this.handleItemHoverEnd(itemKey))
                .on('pointerdown', () => this.handlePurchase(itemKey));
            const text = this.add.text(x, y + 40, `${config.price} Gold\n(${config.stock})`, TEXT_STYLE.item).setOrigin(0.5);
            this.storeItems.set(config.texture, { image, text, config });
        });
    }
    setupInput() {
        this.input.keyboard?.on('keydown-E', this.toggleStore, this);
    }
    handleItemHover(item) {
        const storeItem = this.storeItems.get(item);
        if (storeItem) {
            storeItem.image.setScale(1.1);
            this.soundManager.playSound('hover');
        }
    }
    handleItemHoverEnd(item) {
        const storeItem = this.storeItems.get(item);
        if (storeItem) {
            storeItem.image.setScale(1.0);
        }
    }
    handlePurchase(item) {
        const storeItem = this.storeItems.get(item);
        if (!storeItem)
            return;
        const { config, image, text } = storeItem;
        if (this.validatePurchase(config)) {
            this.processPurchase(item, config);
            this.updateItemDisplay(config, image, text);
            this.soundManager.playSound('coin');
        }
        else {
            this.soundManager.playSound('error');
        }
    }
    validatePurchase(config) {
        return this.player.getGold() >= config.price && config.stock > 0;
    }
    processPurchase(item, config) {
        this.player.addItem(item);
        this.player.updateGold(-config.price);
        config.stock--;
        this.updateGoldText();
    }
    updateItemDisplay(config, image, text) {
        text.setText(`${config.price} Gold\n(${config.stock})`);
        if (config.stock === 0) {
            image.disableInteractive()
                .setTint(0x666666)
                .setAlpha(0.5);
        }
    }
    updateGoldText() {
        this.goldText.setText(`Gold: ${this.player.getGold()}`);
    }
    toggleStore = () => {
        this.cleanup();
        this.player.enableInput();
        this.soundManager.playSound('pageturn');
        this.scene.stop();
    };
    cleanup() {
        this.input.keyboard?.off('keydown-E', this.toggleStore);
        this.storeItems.forEach(item => {
            item.image.off('pointerover');
            item.image.off('pointerout');
            item.image.off('pointerdown');
        });
    }
}
