import Phaser from 'phaser';
import { TILE_SIZE } from '../utils/constants';
import { shuffleArray } from '../utils/helpers';
export default class Items {
    scene;
    player;
    occupiedPositions;
    goldGroup;
    chestGroup;
    specialItemsGroup;
    soundManager;
    maxChests = 5; // Maximum number of chests
    permanentlyVisibleItems; // Track items that are permanently visible
    specialItemConfigs = {
        sword: { key: 'items', frame: 0, probability: 0.1 },
        shield: { key: 'items', frame: 1, probability: 0.15 },
        torch: { key: 'items', frame: 2, probability: 0.2 },
        yarn: { key: 'items', frame: 3, probability: 0.1 },
        map: { key: 'items', frame: 4, probability: 0.1 },
        magicScroll: { key: 'items', frame: 6, probability: 0.05 }
    };
    constructor(scene, player, occupiedPositions, soundManager) {
        this.scene = scene;
        this.player = player;
        this.occupiedPositions = occupiedPositions;
        this.soundManager = soundManager;
        this.goldGroup = this.scene.physics.add.group();
        this.chestGroup = this.scene.physics.add.group();
        this.specialItemsGroup = this.scene.physics.add.group();
        this.permanentlyVisibleItems = new Set();
        // Setup collision handlers
        this.setupCollisions();
    }
    setupCollisions() {
        // Gold collection
        this.scene.physics.add.overlap(this.player, this.goldGroup, (player, gold) => {
            if (gold instanceof Phaser.Physics.Arcade.Sprite) {
                this.collectGold(gold);
            }
        }, undefined, this);
        // Chest interaction
        this.scene.physics.add.overlap(this.player, this.chestGroup, (player, chest) => {
            if (chest instanceof Phaser.Physics.Arcade.Sprite) {
                this.openChest(chest);
            }
        }, undefined, this);
        // Special items collection
        this.scene.physics.add.overlap(this.player, this.specialItemsGroup, (player, item) => {
            if (item instanceof Phaser.Physics.Arcade.Sprite) {
                this.collectSpecialItem(item);
            }
        }, undefined, this);
    }
    addItems(deadEnds) {
        const requiredDeadEnds = 2; // Minimum required dead ends for entrance and exit
        // Ensure there are enough dead ends for chests after entrance, exit, and portals
        if (deadEnds.length > requiredDeadEnds + 2) {
            // Add chests to dead ends, up to the maximum number of chests
            shuffleArray(deadEnds).slice(requiredDeadEnds + 2, requiredDeadEnds + 2 + this.maxChests).forEach((pos) => {
                const chest = this.scene.physics.add.sprite(pos.x * TILE_SIZE, pos.y * TILE_SIZE, 'chest');
                chest.setOrigin(0, 0).setAlpha(0).setData('opened', false); // Make chest invisible initially
                this.chestGroup.add(chest);
                this.occupiedPositions.add(`${pos.x},${pos.y}`);
            });
        }
        // Add portal potion
        const portalPotionPos = this.findRandomOpenTile();
        if (portalPotionPos) {
            const portalPotion = this.scene.physics.add.sprite(portalPotionPos.x * TILE_SIZE, portalPotionPos.y * TILE_SIZE, 'portalpotion');
            portalPotion.setOrigin(0, 0).setAlpha(0);
            this.occupiedPositions.add(`${portalPotionPos.x},${portalPotionPos.y}`);
        }
        // Add random gold pieces to the map
        for (let i = 0; i < 15; i++) { // Adjust the number of gold pieces as needed
            const pos = this.findRandomOpenTile();
            if (pos) {
                const gold = this.scene.physics.add.sprite(pos.x * TILE_SIZE, pos.y * TILE_SIZE, 'gold');
                gold.setOrigin(0, 0).setAlpha(0);
                this.goldGroup.add(gold);
                this.occupiedPositions.add(`${pos.x},${pos.y}`);
            }
        }
        // Add special items
        this.spawnSpecialItems();
    }
    spawnSpecialItems() {
        Object.entries(this.specialItemConfigs).forEach(([itemType, config]) => {
            if (Math.random() < config.probability) {
                const pos = this.findRandomOpenTile();
                if (pos) {
                    this.spawnSpecialItem(itemType, pos.x, pos.y, config);
                }
            }
        });
    }
    spawnSpecialItem(itemType, x, y, config) {
        const item = this.specialItemsGroup.create(x * TILE_SIZE + TILE_SIZE / 2, y * TILE_SIZE + TILE_SIZE / 2, config.key, config.frame);
        item.setData('type', itemType);
        // Add floating animation
        this.scene.tweens.add({
            targets: item,
            y: item.y - 5,
            duration: 1500,
            yoyo: true,
            repeat: -1,
            ease: 'Sine.easeInOut'
        });
        this.occupiedPositions.add(`${x},${y}`);
    }
    collectGold(gold) {
        gold.destroy();
        this.player.addScore(5); // Add points to the player's score
        this.player.addGold(5);
        this.soundManager.playSound('coin'); // Play coin pickup sound
        this.soundManager.playSound('gold2'); // Play a random gold narration
    }
    openChest(chest) {
        if (this.player.hasKey && !chest.getData('opened')) {
            chest.setTexture('chestopen');
            this.player.addScore(25); // Add points to the player's score
            this.player.addGold(25);
            this.soundManager.playSound('chest'); // Play chest opening sound
            this.soundManager.playSound('gold1'); // Play a random gold narration
            chest.setData('opened', true); // Mark chest as opened
        }
    }
    collectSpecialItem(item) {
        const itemType = item.getData('type');
        // Play collection sound
        this.soundManager.playSound('collect');
        // Add item to player's inventory
        this.player.addItem(itemType);
        // Handle special cases
        if (itemType === 'magicScroll') {
            this.scene.events.emit('fullVisibilityChanged', true);
        }
        // Remove the item
        item.destroy();
    }
    findRandomOpenTile() {
        let pos;
        do {
            pos = {
                x: Phaser.Math.Between(1, this.scene.physics.world.bounds.width / TILE_SIZE - 2),
                y: Phaser.Math.Between(1, this.scene.physics.world.bounds.height / TILE_SIZE - 2)
            };
        } while (this.occupiedPositions.has(`${pos.x},${pos.y}`) || !this.isTileOpen(pos));
        return pos;
    }
    isTileOpen(pos) {
        const tileX = Math.floor(pos.x);
        const tileY = Math.floor(pos.y);
        if (tileX < 0 ||
            tileX >= this.scene.physics.world.bounds.width / TILE_SIZE ||
            tileY < 0 ||
            tileY >= this.scene.physics.world.bounds.height / TILE_SIZE) {
            return false;
        }
        const tile = this.scene.children.getByName(`tile-${tileX}-${tileY}`);
        return this.scene.physics.world.bounds.contains(tileX * TILE_SIZE, tileY * TILE_SIZE) &&
            !this.occupiedPositions.has(`${tileX},${tileY}`) &&
            tile !== null && tile !== undefined;
    }
    updateVisibility(fovTiles) {
        const updateObjectVisibility = (object) => {
            if (object) {
                const tileX = Math.floor(object.x / TILE_SIZE);
                const tileY = Math.floor(object.y / TILE_SIZE);
                const itemKey = `${tileX},${tileY}`;
                if (this.player.hasMagicScroll && fovTiles[tileY] && fovTiles[tileY][tileX]) {
                    this.permanentlyVisibleItems.add(itemKey);
                    object.setAlpha(1);
                }
                else if (fovTiles[tileY] && fovTiles[tileY][tileX]) {
                    object.setAlpha(1);
                }
                else if (this.permanentlyVisibleItems.has(itemKey)) {
                    object.setAlpha(1);
                }
                else {
                    object.setAlpha(0);
                }
            }
        };
        this.goldGroup.getChildren().forEach((gold) => {
            updateObjectVisibility(gold);
        });
        this.chestGroup.getChildren().forEach((chest) => {
            updateObjectVisibility(chest);
        });
        this.specialItemsGroup.getChildren().forEach((item) => {
            updateObjectVisibility(item);
        });
    }
    destroy() {
        this.goldGroup.clear(true, true);
        this.chestGroup.clear(true, true);
        this.specialItemsGroup.clear(true, true);
    }
}
