import Phaser from 'phaser';
import { PLAYER_SPEED } from '../utils/constants';
export default class Player extends Phaser.Physics.Arcade.Sprite {
    cursors;
    joystick;
    score;
    gold;
    hasKey;
    hasMagicScroll;
    speed;
    inventory;
    shieldCooldown;
    inputEnabled;
    isInvincible = false;
    currentLevel = 1;
    fovRadius = 1; // Base FOV radius
    trail = [];
    portals = null; // Reference to the Portals class
    isMovingWithKeyboard = false;
    lastMovementTime = 0; // Track the last time the player moved
    idleThreshold = 20000; // Idle threshold in milliseconds (e.g., 20 seconds)
    idleCheckEnabled = false; // Control whether idle checking is active
    soundManager; // Reference to the GlobalSoundManager
    initialTouch = null;
    touchActive = false;
    constructor(scene, x, y, texture, soundManager, data) {
        super(scene, x, y, texture);
        scene.add.existing(this);
        scene.physics.add.existing(this);
        this.soundManager = soundManager;
        this.setOrigin(0.5, 0.5);
        this.setDepth(1);
        const body = this.body;
        body.setSize(this.width * 0.50, this.height * 0.50);
        body.setOffset((this.width - body.width) / 2, (this.height - body.height) / 2);
        if (scene.input.keyboard) {
            this.cursors = scene.input.keyboard.createCursorKeys();
        }
        this.score = 0;
        this.gold = data?.gold || 0;
        this.hasKey = false; // Reset key on level change
        this.hasMagicScroll = false;
        this.speed = PLAYER_SPEED;
        this.inventory = data?.inventory || {};
        this.shieldCooldown = false;
        this.inputEnabled = true;
        this.lastMovementTime = scene.time.now; // Initialize last movement time
        this.soundManager = soundManager; // Set the Sounds class reference
        // Set up the 20-second delay before idle sound checks begin
        scene.time.delayedCall(20000, () => {
            this.idleCheckEnabled = true;
            this.lastMovementTime = scene.time.now; // Reset the timer once idle checking begins
        });
        // Set up touch input handling
        scene.input.on('pointerdown', this.onTouchStart, this);
        scene.input.on('pointermove', this.onTouchMove, this);
        scene.input.on('pointerup', this.onTouchEnd, this);
    }
    update() {
        if (!this.inputEnabled) {
            const body = this.body;
            if (body) {
                body.setVelocity(0);
            }
            return;
        }
        const body = this.body;
        body.setVelocity(0);
        // Handle keyboard input
        let moved = false;
        if (this.cursors?.left?.isDown) {
            body.setVelocityX(-this.speed);
            moved = true;
        }
        else if (this.cursors?.right?.isDown) {
            body.setVelocityX(this.speed);
            moved = true;
        }
        if (this.cursors?.up?.isDown) {
            body.setVelocityY(-this.speed);
            moved = true;
        }
        else if (this.cursors?.down?.isDown) {
            body.setVelocityY(this.speed);
            moved = true;
        }
        // Handle touch input
        if (this.touchActive && this.initialTouch) {
            const pointer = this.scene.input.activePointer;
            const diffX = pointer.x - this.initialTouch.x;
            const diffY = pointer.y - this.initialTouch.y;
            if (Math.abs(diffX) > 10) {
                body.setVelocityX(diffX > 0 ? this.speed : -this.speed);
                moved = true;
            }
            if (Math.abs(diffY) > 10) {
                body.setVelocityY(diffY > 0 ? this.speed : -this.speed);
                moved = true;
            }
            this.initialTouch = pointer;
        }
        // Handle joystick input
        if (this.joystick) {
            const cursorKeys = this.joystick.createCursorKeys();
            if (cursorKeys.left.isDown) {
                body.setVelocityX(-this.speed);
                moved = true;
            }
            else if (cursorKeys.right.isDown) {
                body.setVelocityX(this.speed);
                moved = true;
            }
            if (cursorKeys.up.isDown) {
                body.setVelocityY(-this.speed);
                moved = true;
            }
            else if (cursorKeys.down.isDown) {
                body.setVelocityY(this.speed);
                moved = true;
            }
        }
        if (moved) {
            this.lastMovementTime = this.scene.time.now;
            // Emit event if moving with keyboard
            if (this.cursors && (this.cursors.left.isDown ||
                this.cursors.right.isDown ||
                this.cursors.up.isDown ||
                this.cursors.down.isDown)) {
                this.isMovingWithKeyboard = true;
                this.scene.events.emit('playerStartedKeyboardMovement');
            }
            else {
                this.isMovingWithKeyboard = false;
            }
        }
        // Only check for idle if the idle check is enabled
        if (this.idleCheckEnabled && this.scene.time.now - this.lastMovementTime > this.idleThreshold) {
            this.soundManager.playSound('idle1'); // Play a random idle sound
            this.lastMovementTime = this.scene.time.now; // Reset the idle timer
        }
        this.updateTrail();
    }
    onTouchStart(pointer) {
        this.initialTouch = pointer;
        this.touchActive = true;
    }
    onTouchMove(pointer) {
        if (this.touchActive) {
            const body = this.body;
            const diffX = pointer.x - this.initialTouch.x;
            const diffY = pointer.y - this.initialTouch.y;
            if (Math.abs(diffX) > 10) {
                body.setVelocityX(diffX > 0 ? this.speed : -this.speed);
            }
            if (Math.abs(diffY) > 10) {
                body.setVelocityY(diffY > 0 ? this.speed : -this.speed);
            }
            this.initialTouch = pointer;
        }
    }
    onTouchEnd() {
        this.touchActive = false;
        this.initialTouch = null;
        const body = this.body;
        body.setVelocity(0);
    }
    setJoystick(joystick) {
        this.joystick = joystick;
    }
    updateTrail() {
        const timestamp = this.scene.time.now;
        const position = { x: this.x, y: this.y, timestamp: timestamp };
        this.trail.push(position);
        this.trail = this.trail.filter(pos => (timestamp - pos.timestamp) <= 5000);
    }
    useSword() {
        if (this.hasItem('sword')) {
            console.log('Sword used');
            this.removeItem('sword');
            return true;
        }
        return false;
    }
    useItem(item) {
        if (this.hasItem(item)) {
            this.removeItem(item);
            this.scene.events.emit('updateInventory'); // Emit an event to update the inventory display
            this.soundManager.playSound('itemUsed'); // Play a sound for using an item
        }
    }
    increaseFovRadius() {
        this.fovRadius++;
    }
    decreaseFovRadius() {
        this.fovRadius = Math.max(1, this.fovRadius - 1); // Ensure FOV doesn't go below 1
    }
    getFovRadius() {
        return this.fovRadius;
    }
    resetFovRadius() {
        this.fovRadius = 1; // Reset to base FOV
    }
    setLevel(level) {
        this.currentLevel = level;
    }
    getLevel() {
        return this.currentLevel;
    }
    disableInput() {
        this.inputEnabled = false;
        const body = this.body;
        if (body) {
            body.setVelocity(0);
        }
    }
    enableInput() {
        this.inputEnabled = true;
    }
    addScore(points) {
        this.score += points;
    }
    getScore() {
        return this.score;
    }
    getSpeed() {
        return this.speed;
    }
    addGold(amount) {
        this.gold += amount;
        this.scene.events.emit('inventoryUpdated', this.inventory); // Emit event when gold is added
        this.soundManager.playSound('coin'); // Play coin sound when gold is added
    }
    getGold() {
        return this.gold;
    }
    updateGold(amount) {
        this.gold += amount;
        this.scene.events.emit('inventoryUpdated', this.inventory); // Emit event when gold is updated
    }
    addItem(item) {
        if (!this.inventory[item]) {
            this.inventory[item] = 0;
        }
        this.inventory[item]++;
        this.scene.events.emit('inventoryUpdated', this.inventory); // Emit event when inventory is updated
    }
    getInventory() {
        return this.inventory;
    }
    removeItem(item) {
        if (this.inventory[item] > 0) {
            this.inventory[item]--;
            if (this.inventory[item] === 0) {
                delete this.inventory[item]; // Optional: remove the item from inventory if count is zero
                this.scene.events.emit('inventoryUpdated', this.inventory);
            }
            this.scene.events.emit('inventoryUpdated', this.inventory); // Emit event when inventory is updated
        }
    }
    hasItem(item) {
        return (this.inventory[item] && this.inventory[item] > 0) || false;
    }
    getTorchCount() {
        return this.inventory['torch'] || 0;
    }
    useShield() {
        if (this.hasItem('shield') && !this.shieldCooldown) {
            this.soundManager.playSound('shield'); // Play shield sound
            this.removeItem('shield');
            this.shieldCooldown = true;
            this.scene.time.delayedCall(5000, () => {
                this.shieldCooldown = false;
            }, [], this);
            return true;
        }
        return false;
    }
    setInvincible(duration) {
        this.isInvincible = true;
        this.scene.time.delayedCall(duration, () => {
            this.isInvincible = false;
        });
    }
    isCurrentlyInvincible() {
        return this.isInvincible;
    }
    usePortalPotion() {
        if (this.hasItem('portalPotion') && this.portals) {
            console.log("Portal potion used");
            this.portals.spawnTemporaryPortal(); // Spawn the temporary portal
            this.removeItem('portalPotion'); // Use up one portal potion
        }
        else {
            console.log("No portal potion available.");
        }
    }
    getPortalPotionCount() {
        return this.inventory['portalPotion'] || 0;
    }
    setPortals(portals) {
        this.portals = portals; // Set the reference to the Portals class
    }
    // Add the resetAll function
    resetAll() {
        this.score = 0;
        this.gold = 0;
        this.hasKey = false;
        this.hasMagicScroll = false;
        this.speed = PLAYER_SPEED;
        this.inventory = {};
        this.shieldCooldown = false;
        this.inputEnabled = true;
        this.isInvincible = false;
        this.currentLevel = 1;
        this.fovRadius = 1;
        this.trail = [];
        this.portals = null;
        this.scene.registry.set('collectedNotes', []); // Clear collected notes in the registry
    }
    // Collect a note and store it in the registry
    collectNote(noteContent) {
        const collectedNotes = this.scene.registry.get('collectedNotes') || [];
        if (!collectedNotes.includes(noteContent)) {
            collectedNotes.push(noteContent);
            this.scene.registry.set('collectedNotes', collectedNotes);
        }
    }
    // Get collected notes from the registry
    getCollectedNotes() {
        return this.scene.registry.get('collectedNotes') || [];
    }
}
